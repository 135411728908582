export default [
  {
    component: () => import('@/modules/learningmaterials/views/LearningmaterialsDashboard.vue'),
    name: 'dashboard',
    path: '/learningmaterials',
    meta: {
      requiresModule: 'learningmaterials',
    },
  },
  {
    component: () => import('@/modules/learningmaterials/views/LearningmaterialDisplay.vue'),
    name: 'details',
    path: '/learningmaterials/:id',
    meta: {
      requiresModule: 'learningmaterials',
    },
    children: [
      {
        component: () => import('@/modules/viewers/components/WbtViewer.vue'),
        name: 'wbt-viewer',
        path: 'viewer',
        meta: {
          requiresModule: 'learningmaterials',
          module: 'learningmaterials',
        },
      },
    ],
  },

  {
    component: () => import('@/modules/learningmaterials/views/LearningmaterialFolder.vue'),
    name: 'folder',
    path: '/learningmaterials/folder/:id',
    meta: {
      requiresModule: 'learningmaterials',
    },
  },
]
