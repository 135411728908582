// only 4 boxes
// cards don't go back at all on wrong answer
// no time limits

import {
  format, toDate, utcToZonedTime,
} from 'date-fns-tz'
import Dexie from 'dexie'

import constants from '@/constants'
import helpers from '@/logic/helpers'
import PowerlearningAPI from '@/modules/powerlearning/api/powerlearning'

const db = new Dexie('powerlearning')
db.version(1).stores({
  questions: 'id,category_id,box,updated,[category_id+box]',
  categories: 'id,categorygroup_id',
  categorygroups: 'id',
  media: 'url',
  settings: 'key',
})

export default {
  store: null,
  worker: null,
  lastQuestionIds: [],
  /**
   * This method is called once when the app is initialized
   */
  setup(store) {
    this.store = store
  },
  getDB() {
    return db
  },
  /**
   * Returns all available categories
   */
  getCategories() {
    return this.getSetting('sort_categories_alphabetically').then((sortAlpha) => {
      const categoriesCallback = db.categories.toArray()
      if (sortAlpha === 'true') {
        return categoriesCallback.then((categories) => categories.sort((categoryA, categoryB) => categoryA.name.localeCompare(categoryB.name)))
      }
      return categoriesCallback
    })
  },
  /**
   * Returns all available category groups
   */
  getCategoryGroups() {
    return this.getSetting('sort_categories_alphabetically').then((sortAlpha) => {
      const categoryGroupsCallback = db.categorygroups.toArray()
      if (sortAlpha === 'true') {
        return categoryGroupsCallback.then((categoryGroups) => categoryGroups.sort((categoryGroupA, categoryGroupB) => categoryGroupA.name.localeCompare(categoryGroupB.name)))
      }
      return categoryGroupsCallback
    })
  },
  /**
   * getCategoryGroup returns a category-group or undefined.
   * @param {Number} category_group_id
   */
  getCategoryGroup(categoryGroupId) {
    if (!categoryGroupId) {
      return Promise.resolve(null)
    }
    return db.categorygroups.where('id').equals(categoryGroupId).first()
  },
  /**
   * getCategoryGroupByCategory returns a category-group or undefined.
   * @param {Number} categoryId
   */
  getCategoryGroupByCategory(categoryId) {
    return this.getCategory(categoryId)
      .then((category) => {
        if (!category) {
          return undefined
        }
        return this.getCategoryGroup(category.categorygroup_id)
      })
  },
  /**
   * Returns the category
   * @param {Number} categoryId
   */
  getCategory(categoryId) {
    return db.categories.where('id').equals(categoryId).first()
  },
  /**
   * getSetting returns a setting's value or null.
   * @param {string} settingKey
   */
  getSetting(settingKey) {
    return db.settings.where('key').equals(settingKey).first((setting) => (setting ? setting.value : null))
  },
  /**
   * Returns a random question for the categoryId
   *
   * @param {any} categoryId
   */
  getQuestion(categoryId, difficulty, box) {
    return new Promise((resolve, reject) => {
      const query = {
        box,
      }
      if (categoryId !== -1) {
        query.category_id = categoryId
      }
      db.questions
        .where(query)
        .toArray((questions) => {
          const difficultyOffset = 0.1
          let question = null
          if (questions.length === 1) {
            question = questions[0]
          } else if (questions.length) {
            question = this.selectQuestionByDifficulty(questions, difficulty, difficultyOffset, box, categoryId)
          }
          if (question) {
            const lastQuestionIds = this.getLastQuestionIds(box, categoryId)
            lastQuestionIds.push(question.id)
          }
          resolve({
            count: questions.length,
            question,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  selectQuestionByDifficulty(questions, difficulty, difficultyOffset, box, categoryId) {
    // Shuffle questions so it's less likely to get similar questions again and again
    helpers.shuffle(questions)
    // Remove all questions from the list of questions in the current box that have recently been asked
    // so we don't potentially ask the same questions over and over again.
    const lastQuestionIds = this.getLastQuestionIds(box, categoryId)
    let filteredQuestions = questions.filter((question) => !lastQuestionIds.includes(question.id))
    // Check if we don't have enough questions anymore
    if (filteredQuestions.length <= 1) {
      if (lastQuestionIds.length > 0 && questions.length > 1) {
        // Check if we have recent questions, if yes, we only remove the last question
        filteredQuestions = questions.filter((question) => question.id !== lastQuestionIds[lastQuestionIds.length - 1])
      } else {
        // If we don't have recent questions, we just take all questions that are relevant
        filteredQuestions = [...questions]
      }
      // We ended up with only one question with has not been recently asked. This happens when the user has reached the last question in the given box.
      // In the last box, the user potentially wants to immediately answer all the questions again, so we reset the last question ids so that they can start fresh.
      this.lastQuestionIds[`${box}-${categoryId}`] = []
    }
    if (difficultyOffset >= 10) {
      return filteredQuestions[Math.floor(Math.random() * filteredQuestions.length)]
    }
    filteredQuestions = filteredQuestions.filter((question) => Math.abs(difficulty - question.difficulty) <= difficultyOffset)
    if (!filteredQuestions.length) {
      return this.selectQuestionByDifficulty(questions, difficulty, difficultyOffset + 0.1, box, categoryId)
    }
    return filteredQuestions[Math.floor(Math.random() * filteredQuestions.length)]
  },
  getLastQuestionIds(box, categoryId) {
    const idx = `${box}-${categoryId}`
    if (this.lastQuestionIds[idx] === undefined) {
      this.lastQuestionIds[idx] = []
    }
    return this.lastQuestionIds[idx]
  },
  /**
   * Returns all questions for the categoryId and box
   *
   * @param {any} categoryId
   */
  getQuestions(categoryId, box) {
    categoryId = parseInt(categoryId, 10)
    box = parseInt(box, 10)
    return new Promise((resolve, reject) => {
      db.questions
        .where({
          box,
          categoryId,
        })
        .toArray((questions) => {
          resolve({
            questions,
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /**
   * Saves an answer to the indexedDB
   *
   * @param {any} questionId
   * @param {any} answerIds
   */
  saveAnswer(questionId, answerIds) {
    return new Promise((resolve, reject) => {
      db.questions.get(questionId).then((question) => {
        const answerCorrect = this.answerCorrect(question, answerIds)
        let newBox
        let newDifficulty = question.difficulty
        if (answerCorrect) {
          newBox = Math.min(question.box + 1, 3)
          // If the user knew the answer to the question it was apparently quite easy, so we decrease it's difficulty
          newDifficulty += 0.1
        } else {
          newBox = 0
          // If the user didn't know the answer to the question it was apparently not so easy, so we increase it's difficulty
          newDifficulty -= 0.1
        }
        const now = parseInt(format(new Date(), 'T'), 10)
        db.questions.update(question.id, {
          box: newBox,
          updated: 1,
          box_entered_at: now,
          difficulty: newDifficulty,
        }).then(() => {
          this.submitAnswers()
        })
        const correctAnswerIds = question.answers.filter((a) => a.correct).map((a) => a.id)
        resolve({
          correctAnswerIds,
          result: answerCorrect,
          feedback: this.getFeedback(question, answerIds),
        })
        db.categories.update(question.category_id, {
          last_answered_at: now,
        })
      }).catch((e) => {
        reject(e)
      })
    })
  },
  /**
   * Checks if a given answer is correct
   *
   * @param {any} question
   * @param {any} answerIds
   */
  answerCorrect(question, answerIds) {
    if (answerIds === -1) {
      return false
    }

    if (question.type === constants.QUESTION.TYPE_MULTIPLE_CHOICE) {
      // Multiple choice
      let isCorrect = true
      question.answers.forEach((answer) => {
        if (answer.correct && answerIds.indexOf(answer.id) === -1) {
          isCorrect = false
        }
        if (!answer.correct && answerIds.indexOf(answer.id) !== -1) {
          isCorrect = false
        }
      })
      return isCorrect
    } if (question.type === constants.QUESTION.TYPE_LEARN_CARD) {
      // Indexcards
      return !!(answerIds && answerIds.length > 0 && answerIds[0])
    }
    // Single choice
    const answer = question.answers.find((entry) => entry.id === answerIds)
    if (!answer) {
      return false
    }
    return answer.correct
  },
  /**
   * Returns feedback for the given answers
   *
   * @param {any} question
   * @param {any} answerIds
   */
  getFeedback(question, answerIds) {
    if (answerIds === null) {
      answerIds = []
    } else if (typeof answerIds !== 'object') {
      answerIds = [answerIds]
    }
    const feedback = {}
    question.answers.forEach((answer) => {
      if (
        answerIds.indexOf(answer.id) !== -1
          || answer.correct
      ) {
        feedback[answer.id] = answer.feedback
      }
    })
    return feedback
  },
  getTimezoneOffset() {
    return new Date().getTimezoneOffset()
  },
  /**
   * Submit all updated questions to the backend
   */
  submitAnswers() {
    return new Promise((resolve, reject) => {
      if (this.store.getters.offline) {
        reject()
        return
      }
      db.questions.where('updated').equals(1).toArray().then((questions) => {
        if (!questions.length) {
          resolve()
          return
        }
        const data = questions.map((question) => {
          const enteredAtUTC = toDate(question.box_entered_at, 'T', this.getTimezoneOffset())
          const enteredAtServer = utcToZonedTime(enteredAtUTC, 'Europe/Berlin')
          return {
            id: question.id,
            box: question.box,
            box_entered_at: format(enteredAtServer, 'yyyy-MM-dd HH:mm:ss'),
          }
        })
        PowerlearningAPI.saveLearningData(data).then(resolve)
      })
    })
  },
  /**
   * Returns statistics for box counts
   */
  getBoxStatistics(categoryIds = null) {
    return new Promise((resolve, reject) => {
      let categories = db.categories
      if (categoryIds !== null) {
        categories = categories.where('id').anyOf(categoryIds)
      }
      categories.toArray((dbCategories) => {
        const stats = {
          categories: {},
          total: [0, 0, 0, 0],
        }
        const promises = []
        dbCategories.forEach((category) => {
          [0, 1, 2, 3].forEach((box) => {
            promises.push(db.questions.where({ category_id: category.id, box }).count().then((count) => {
              if (typeof stats.categories[category.id] === 'undefined') {
                stats.categories[category.id] = { total: 0 }
              }
              stats.categories[category.id][box] = count
              stats.categories[category.id].total += count
              stats.total[box] += count
            }))
          })
        })
        Promise.all(promises).then(() => {
          resolve(stats)
        }).catch(reject)
      })
    })
  },
  async getCategoriesStatistics() {
    let groups = await this.getCategoryGroups()
    // Add empty default group for categories without group
    const defaultGroup = {
      id: -1,
    }
    groups.push(defaultGroup)
    const categories = await this.getCategories()
    groups = groups.map((group) => {
      group.categories = []
      return group
    })
    const statistics = await this.getBoxStatistics()
    categories.forEach((category) => {
      let group = groups.find((entry) => entry.id === category.categorygroup_id)
      if (!group) {
        group = defaultGroup
      }
      category.stats = statistics.categories[category.id]
      group.categories.push(category)
    })
    // Only return groups with at least one category
    groups = groups.filter((group) => group.categories.length > 0)
    return groups
  },
  getCategorygroupBoxStatistics(categoryGroups) {
    if (!categoryGroups) {
      return null
    }
    const boxStats = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      total: 0,
    }
    categoryGroups.forEach((group) => {
      group.categories.forEach((category) => {
        Object.keys(category.stats).forEach((idx) => {
          boxStats[idx] += category.stats[idx]
        })
      })
    })
    return boxStats
  },
  async getCategoryStatistics(categoryId) {
    const category = await this.getCategory(categoryId)
    if (!category) {
      return null
    }
    const statistics = await this.getBoxStatistics(categoryId)
    category.group = await this.getCategoryGroup(category.categorygroup_id)
    category.stats = statistics.categories[category.id]
    return category
  },
}
